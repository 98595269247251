import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import {
  always,
  compose,
  cond,
  T,
  test
} from 'ramda'

import styles from './index.css'
import Screen from '../../containers/screen'
import CategoryNavigation from './category-navigation'
import CATEGORY_COMPONENT_FRAGMENT from '../../../graphql/fragments/category-component.gql'
import { HelmetIfNoModal } from '../helmet-if-no-modal'
import { getLocationBaseWithHref } from '../../lib/location'
import CategoryContextProvider from './category-context'

export const getCategoryMeta = (pathname, selectedCategory) => {
  return compose(cond([
    [test(/^\/tv-series\/.*/), always({
      title: `Watch ${selectedCategory.name} TV shows online with Lightbox`,
      description: `Lightbox has a great selection of ${selectedCategory.name} TV shows.`
    })],
    [test(/^\/browse-movies\/.*/), always({
      title: `Watch ${selectedCategory.name} movies online with Lightbox`,
      description: `Watch ${selectedCategory.name} movies online. No subscription required.`
    })],
    [test(/^\/browse-rentals\/.*/), always({
      title: `Rent ${selectedCategory.name} movies online with NEON`,
      description: `Rent ${selectedCategory.name} movies online. No subscription required.`
    })],
    [test(/^\/browse-kids\/.*/), always({
      title: `Watch ${selectedCategory.name} kids shows online with Lightbox`,
      description: `Lightbox has a great selection of ${selectedCategory.name} kids shows.`
    })],
    [T, always({})]
  ]))(pathname)
}

export const getNeonCategoryMeta = (pathname, selectedCategory) => {
  return compose(cond([
    [test(/^\/tv-series\/.*/), always({
      title: `Watch ${selectedCategory.name} TV shows online with NEON`,
      description: `NEON has a great selection of ${selectedCategory.name} TV shows.`
    })],
    [test(/^\/browse-movies\/.*/), always({
      title: `Watch ${selectedCategory.name} movies online with NEON`,
      description: `NEON has a great selection of ${selectedCategory.name} movies.`
    })],
    [test(/^\/browse-rentals\/.*/), always({
      title: `Rent ${selectedCategory.name} movies online with NEON`,
      description: `Rent ${selectedCategory.name} movies online. No subscription required.`
    })],
    [test(/^\/browse-kids\/.*/), always({
      title: `Watch ${selectedCategory.name} kids shows online with NEON`,
      description: `NEON has a great selection of ${selectedCategory.name} kids shows.`
    })],
    [test(/^\/browse-max\/.*/), always({
      title: `Watch and stream ${selectedCategory.name} online with NEON`,
      description: `NEON has a great selection of ${selectedCategory.name} movies and shows.`
    })],
    [T, always({})]
  ]))(pathname)
}

function Category({
  category,
  selectedCategoryIdx,
  location,
  ...props
}) {
  if (!category.items.length) {
    return null
  }

  const selectedCategory = category.items[selectedCategoryIdx]
  const linkedNavItemName = category.linkedNavItemName
  const { title, description } = getNeonCategoryMeta(location.pathname, selectedCategory)

  return (
    <CategoryContextProvider selectedCategory={selectedCategory} linkedNavItemName={linkedNavItemName}>
      <div className={classNames(
        styles.category
      )}
      >
        <HelmetIfNoModal>
          <meta property="og:url" content={getLocationBaseWithHref()} />
          <meta
            name="description"
            property="og:description"
            content={description}
          />
          <meta name="title" property="og:title" content={title} />
          <title>{title}</title>
        </HelmetIfNoModal>
        <div className={styles.gradientTop} />
        <CategoryNavigation
          {...props}
          selectedCategoryIdx={selectedCategoryIdx}
          categoryItems={category.items}
        />
        <Screen screenId={category.items[selectedCategoryIdx].id} />
      </div>
    </CategoryContextProvider>
  )
}

Category.propTypes = {
  category: propType(CATEGORY_COMPONENT_FRAGMENT).isRequired,
  selectedCategoryIdx: PropTypes.number.isRequired
}

export default Category
